import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as Sentry from '@sentry/react';

import reportWebVitals from './reportWebVitals';

import './index.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { DATA_CONSTANT } from 'constants/data.constant';
import App from 'App';

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        detection: {
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            useSuspense: false,
        },
    });

Sentry.init({
    release: '1.0.0',
    dsn: 'https://a7b8102ca43846348a3608f412cb4d23@o1095904.ingest.sentry.io/4505485462863872',
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
    tracesSampleRate: 1.0, //lower the value in production
    environment: window.location.hostname === 'localhost' ? 'local' : process.env.REACT_APP_ENV,
    normalizeDepth: 10,
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={DATA_CONSTANT.SSO_CREDENTIALS.REACT_APP_GOOGLE_CLIENT_ID}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </GoogleOAuthProvider>
    </React.StrictMode>,
);

reportWebVitals();
