import { AllInterests, emoji, generateInterestsProfile } from './interest.constant';

export const DATA_CONSTANT = {
    DEFAULT_COUNTRY_CODE: 'default',

    SIGN_UP_INTEREST_ALL: AllInterests,
    SIGN_UP_INTEREST_EMOJI: emoji,
    SIGN_UP_INTEREST_GENERATE_PROFILE: generateInterestsProfile,

    REGEX: {
        //eslint-disable-next-line
        EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/,
        PASSWORD: /^(?=.*[a-zA-Z])(?=.*[0-9])/,
    },

    LEAD_SOURCE_ARRAY: [
        { label: 'leadSource_onlineSearch', value: 'onlineSearch' },
        // { label: 'leadSource_schoolOrganization', value: 'schoolOrganization' },
        // { label: 'leadSource_friendsColleagues', value: 'friendsColleagues' },
        { label: 'leadSource_organizationOrColleagues', value: 'organizationOrColleagues' },
        { label: 'leadSource_webinarTraining', value: 'webinarTraining' },
        { label: 'leadSource_socialMedia', value: 'socialMedia' },
        // { label: 'leadSource_other', value: 'other' },
        // { label: 'leadSource_tiktok', value: 'tiktok' },
        // { label: 'leadSource_otherSocialMedia', value: 'otherSocialMedia' },
    ],

    JOB_ROLE_ARRAY: [
        // { label: 'jobRole_educatorPrimary', value: 'educatorPrimary' },
        // { label: 'jobRole_educatorSecondary', value: 'educatorSecondary' },
        // { label: 'jobRole_educatorHigherEd', value: 'educatorHigherEd' },
        // { label: 'jobRole_schoolAdmin', value: 'schoolAdmin' },
        { label: 'jobRole_teacher', value: 'teacher' },
        { label: 'jobRole_trainer', value: 'trainer' },
        { label: 'jobRole_corporateProfessional', value: 'corporateProfessional' },
        { label: 'jobRole_student', value: 'student' },
        { label: 'jobRole_other', value: 'other' },
        // { label: 'jobRole_schoolHead', value: 'schoolHead' },
    ],

    INPUT_LIMITS: {
        EMAIL_MAX: 60,
        FIRST_LAST_NAME_MAX: 15,
        PASSWORD_MIN: 6,
        PASSWORD_MAX: 20,
        ORGANIZATION_MIN: 2,
        ORGANIZATION_MAX: 100,
        MOBILE_MIN: 9,
        MOBILE_MAX: 11,
        MOBILE_COUNTRY_CODE_MAX: 4,
    },

    SSO_CREDENTIALS: {
        REACT_APP_FACEBOOK_CLIENT_ID: '1183519125674711',
        REACT_APP_GOOGLE_CLIENT_ID: '575473940983-eeq0h6g5skka4dugc2s2e5qgddtf21f8.apps.googleusercontent.com',
        REACT_APP_GOOGLE_REDIRECT_URL: 'https://localhost:3010/signup',
        REACT_APP_MICROSOFT_APP_ID: '4b40f069-b204-4b5e-8424-a1b591ffb8a7',
    },

    CP_INSTALLER_URL: 'https://download.inknoe.com/ClassPoint-Setup.exe',
    ENCRYPT_DECRYPT_KEY: 'cp2',
};
