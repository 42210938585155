const CpInterests = {
    gamification: ['classroomManagement', 'awardStars', 'leaderboard', 'namePicker'],
    presentationTools: ['draggableObjects', 'embeddedBrowser', 'annotation', 'timer'],
    interactiveQuizzes: ['formativeAssessment', 'multipleChoice', 'wordCloud', 'shortAnswer'],
};

// flatten CpInterests into string array
export const AllInterests = Object.values(CpInterests).reduce((acc, val) => acc.concat(val), []);

export const emoji = (interest: string) => {
    const interestEmojiObject = {
        embeddedBrowser: '🌐',
        namePicker: '🎲',
        annotation: '✏️',
        timer: '⏳',
        classroomManagement: '🙋',
        awardStars: '⭐️',
        leaderboard: '🏆',
        formativeAssessment: '📝',
        draggableObjects: '📦',
        multipleChoice: '📊',
        wordCloud: '☁️',
        shortAnswer: '💬',
    };
    return interestEmojiObject[interest as keyof typeof interestEmojiObject] || '';
};

export const generateInterestsProfile = (interests: string[]) => {
    const interestsProfile = { gamification: 0, presentationTools: 0, interactiveQuizzes: 0 };
    interests.forEach((interest) => {
        if (CpInterests.gamification.includes(interest)) interestsProfile.gamification += 1;
        else if (CpInterests.presentationTools.includes(interest)) interestsProfile.presentationTools += 1;
        else if (CpInterests.interactiveQuizzes.includes(interest)) interestsProfile.interactiveQuizzes += 1;
    });
    return interestsProfile;
};
